<template>
    <div class="user page normal-page">
        <div class="web-content">
            <div class="page-structure">
                <PageMenu :menu-data="pageMenu" />

                <div class="page-content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageMenu from '@/components/PageMenu.vue';

export default {
    components: {
        PageMenu,
    },
    data() {
        return {
            pageMenu: {
                // eslint-disable-next-line global-require
                icon: require('../../assets/user/menu-icon.png'),
                title: 'user.PageMenu.title',
                items: [
                    {
                        type: 'router',
                        text: 'user.PageMenu.items.text[0]',
                        routeName: 'Profile',
                    },
                    {
                        type: 'panel',
                        text: 'user.PageMenu.items.text[1]',
                        blockItems: [
                            {
                                type: 'router',
                                text: 'user.PageMenu.items.text[2]',
                                routerName: 'UserInvention',
                            },
                            {
                                type: 'router',
                                text: 'user.PageMenu.items.text[3]',
                                routerName: 'UserIncubator',
                            },
                        ],
                    },
                    {
                        type: 'router',
                        text: 'user.PageMenu.items.text[4]',
                        routeName: 'ChangePassword',
                    },
                ],
            },
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss">
.user {
  padding: 68px 0 150px 0;

  .page-content {
    margin-left: 100px;
  }

  .action {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .btn {
      width: 40%;
      height: 60px;
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.1em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
    }

    .cancel-btn {
      background: #bbbbbb;
    }
  }
}

@media screen and (max-width: 1024px) {
  .user {
    .page-content {
      margin-left: 0;
    }
  }
}
</style>
